<template>
  <div>
    <content id="user-page" class="d-block bg-light">
      <header
        class="
          text-center
          d-flex
          align-items-center
          justify-content-center
          flex-column
          h0
          not-found-header
        "
      >
        <p style="font-size: 30px">Ulaşmaya çalıştığınız sayfa bulunamadı</p>
      </header>
      <main class="container full py-5 border-top">
        <div class="notFound text-center">
          <h2>404</h2>
          <p>Sistemde böyle bir kullanıcı bulunamadı!</p>
        </div>
      </main>
    </content>
    <footer id="footer">
      <div class="container full h-100">
        <div class="row align-content-center h-100">
          <a
            class="col-lg-6 footer-brand text-center text-lg-left mb-2"
            href="/"
          >
            <svg width="24px" height="24px">
              <path
                fill-rule="evenodd"
                fill="#737d92"
                d="M18.319,12.626 L16.162,12.626 L16.162,14.979 L18.319,14.979 C20.158,14.979 21.654,16.475 21.654,18.313 C21.654,20.151 20.158,21.647 18.319,21.647 C16.496,21.647 15.010,20.176 14.986,18.359 L14.986,11.371 L18.319,11.371 C21.455,11.371 24.007,8.820 24.007,5.686 C24.007,2.550 21.455,-0.001 18.319,-0.001 C15.183,-0.001 12.632,2.550 12.632,5.686 L12.632,7.841 L14.986,7.840 L14.985,5.686 C14.985,3.848 16.481,2.352 18.319,2.352 C20.158,2.352 21.654,3.848 21.654,5.686 C21.654,7.523 20.158,9.018 18.319,9.018 L11.376,9.018 L11.376,5.686 C11.376,2.550 8.824,-0.001 5.688,-0.001 C2.552,-0.001 0.000,2.550 0.000,5.686 C0.000,8.820 2.552,11.371 5.688,11.371 L7.845,11.371 L7.845,9.018 L5.688,9.018 C3.850,9.018 2.354,7.523 2.354,5.686 C2.354,3.848 3.850,2.352 5.688,2.352 C7.526,2.352 9.022,3.848 9.022,5.686 L9.022,12.626 L5.688,12.626 C2.552,12.626 0.000,15.177 0.000,18.313 C0.000,21.449 2.552,23.999 5.688,23.999 C8.726,23.999 11.215,21.606 11.368,18.606 L11.376,16.159 L9.022,16.159 L9.022,18.313 C9.022,20.151 7.526,21.647 5.688,21.647 C3.850,21.647 2.354,20.151 2.354,18.313 C2.354,16.475 3.850,14.979 5.688,14.979 L12.632,14.979 L12.632,18.313 C12.632,21.449 15.183,23.999 18.319,23.999 C21.455,23.999 24.007,21.449 24.007,18.313 C24.007,15.177 21.455,12.626 18.319,12.626 ZM11.376,12.626 L11.376,11.371 L12.632,11.371 L12.632,12.626 L11.376,12.626 Z"
              ></path>
            </svg>
          </a>

          <span class="col-12 text-center text-lg-left"
            >Copyright 2019 Doğuş Teknoloji</span
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
